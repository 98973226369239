import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import DateField from '../../../../atoms/DateField/DateField';
import InputField from '../../../../atoms/Input/Input';
import FileUPload from '../../../../atoms/FileUpload/FileUPload';
import {
  checkForValidDate,
  checkForValidDateForIsoFormat,
  formattedDateTimeValue,
  formattedTimeValue,
  getDate,
  getDateTime,
  getFormattedValues,
  getTimeDifferenceMilliseconds,
  utcToLocal,
} from '../../../../utils/formatter';
import { DateFormatters } from '../../../../constants/dateConstants';
import { useDispatch } from 'react-redux';
import {
  FormFieldTypes,
  LoadingStatus,
  ToastThemes,
  toastMessage,
} from '../../../../constants/common';
import { clone, isEmpty } from 'lodash';
import {
  FormFieldNames,
  ToastMessages,
  stringSubstitute,
} from '../../Organization/constants/onboarding.constants';
import ApiSelectTag from '../../../../atoms/Select/ApiSelectTag';
import { validateEmail } from '../../../../validations/validation';
import { fileUpload } from '../../../../redux/main/action';
import { preventKeyPress, showToast } from '../../../../utils/common.util';
import CircularLoader from '../../../../atoms/CircularLoader/circular-loader';
import moment from 'moment';
import AutocompleteCheckbox from '../../../../atoms/Autocomplete/Checkboxes';
import {
  DateRangeValidations,
  NumberValidation,
  RangeValueTypes,
  checkForZero,
  getFormulaExpression,
  getSubType,
  readConditions,
} from './util/form.util';
import TimeField from '../../../../atoms/time/TimeField';
import RatingInput from '../../../../atoms/Rating/RatingInput';
import DateTimeField from '../../../../atoms/DateTimeField/DateTimeField';
import CheckBoxInput from '../../../../atoms/checkBox/checkBox';
import ButtonField from '../../../../atoms/Buttons/button';
import DateRangeForm from '../../../molecules/DateRange/DateRangeForm';
import ActionHeader from '../../Organization/ActionHeader/ActionHeader';
import {
  getHiddenMandatoryFields,
  getValidationErrors,
} from '../../Organization/utils/userOnboarding/userOnboarding.util';
import MutliFileUpload from '../../../../atoms/FileUpload/MutliFileUpload';
import MonthField from '../../../../atoms/Date/Month';
import { useLocation } from 'react-router';
import { BuilderServices } from '../../../../services/builder';
import { preventKeyPressWithoutDecimal } from '../../../forms/claim-form-render/add-form-data/unergizer-claim-form/utils/unergizer-utils';
import YearField from '../../../../atoms/Date/Year';
import { CLAIM_STATUS } from '../../../claim-view/claim-audit-log';
import { getTRFClaimForm } from '../../../../redux/form-builder/action';

const FormEdit = (props) => {
  const {
    list = [],
    formInfo = {},
    setFormData = () => {},
    errors = {},
    setErrors = () => {},
    setDisabledSave = () => {},
    handleDropdownClick = () => {},
    getListLoadingStatus = () => {},
    getDropdownOptions = () => {},
    padding = [5, 10, 25],
    editClick = () => {},
    button,
    deleteClick = () => {},
    hideFields = true,
    modal = true,
    isActionHeader = false,
    actionHeader = {},
    publicHoliday = [],
    calculate = () => {},
  } = props;

  const {
    onPublishClick,
    handleSaveAndAddCopyClick,
    handleSaveAndAddNewClick,
    ...actionHeaderProps
  } = actionHeader;

  const dispatch = useDispatch();
  const [fileLoading, setFileLoading] = useState(-1);
  const [fileRes, setFileRes] = useState();
  const [formItems, setFormItems] = useState(list);
  const [isAllSelected, setIsAllSelected] = useState({});
  const [calculateSales, setCalculateSales] = useState({ calculate: false });
  const formDataUpdated = useRef(false);
  const location = useLocation();
  const paths = location.pathname.split('/');
  const claimTypeId = Number(paths[paths.length - 2]);
  const idForDetails = Number(paths[paths.length - 1]);

  useEffect(() => {
    setFormItems(list);
  }, [list]);

  useEffect(() => {
    if (formDataUpdated.current) {
      formDataUpdated.current = false;
      return;
    }
    setFormItems((prev) => {
      const newList = prev.map((item) => {
        let temp = item;
        const { conditional, range, fieldRefName, dateConditional } = temp;
        if (conditional) {
          const { rules, action } = conditional;
          const isValid = rules.some((rule) => {
            const { conjunction, conditions } = rule;
            if (
              readConditions(conditions, conjunction, formInfo, publicHoliday)
            ) {
              return true;
            }
          });
          if (!isValid && action === 'isHidden' && formDataUpdated) {
            setFormData((prev) => {
              formDataUpdated.current = true;
              return {
                ...prev,
                [fieldRefName]: null,
              };
            });
          }
          if (action)
            temp = {
              ...temp,
              [action]: !isValid,
            };
        }

        const rangeValues = getMinMaxVal(range);
        if (rangeValues) {
          const { minLimitVal, maxLimitVal, disableFuture, disablePast } =
            rangeValues;
          temp = {
            ...temp,
            minDate: minLimitVal,
            maxDate: maxLimitVal,
            disableFuture,
            disablePast,
          };
        }
        if (dateConditional) {
          const shouldDisable = getFormulaExpression(dateConditional, formInfo);
          temp = {
            ...temp,
            shouldDisable,
          };
        }

        return temp;
      });
      return newList;
    });
  }, [formInfo]);

  useEffect(() => {
    if (!isNaN(idForDetails)) {
      formDataUpdated.current = false;
    }
  }, [idForDetails, formItems]);

  useEffect(() => {
    handleChangeFormulaFields(calculateSales?.percision);
  }, [calculateSales?.calculate]);

  const getMinMaxVal = (range) => {
    if (range) {
      const { type, min, max, fieldType, isResubmitted, newmin, newmax } =
        range;
      switch (type) {
        case RangeValueTypes.DisabledFuture: {
          return { disableFuture: true };
        }
        case RangeValueTypes.DisabledPast: {
          return { disablePast: true };
        }
        case RangeValueTypes.Range: {
          let minLimitVal = null;
          let maxLimitVal = null;
          if (
            isResubmitted &&
            !isNaN(idForDetails) &&
            formInfo?.status !== CLAIM_STATUS.DRAFT
          ) {
            if (newmin) {
              minLimitVal = getLimitValueDate(newmin, fieldType);
            }
            if (newmax) {
              maxLimitVal = getLimitValueDate(newmax, fieldType);
            }
          } else {
            if (min) {
              minLimitVal = getLimitValueDate(min, fieldType);
            }
            if (max) {
              maxLimitVal = getLimitValueDate(max, fieldType);
            }
          }
          return { minLimitVal, maxLimitVal };
        }
      }
    }
    return null;
  };

  const getLimitValueDate = (limit, fieldType) => {
    const { name, operator, value = 0, type } = limit;
    let momentObj = null;
    if (name === 'today') {
      momentObj = moment();
    } else if (name === 'createdAt') {
      const newValue = utcToLocal(
        formInfo?.[name],
        'YYYY-MM-DD HH:mm',
        'YYYY-MM-DD'
      );
      momentObj = getDate(newValue, DateFormatters.YYYYMMDD);
    } else {
      switch (fieldType) {
        case FormFieldTypes.DateTime: {
          const newValue = utcToLocal(
            formInfo?.[name],
            'YYYY-MM-DD HH:mm',
            'YYYY-MM-DD hh:mm a'
          );
          momentObj = getDateTime(newValue, 'YYYY-MM-DD hh:mm a');
          break;
        }
        case FormFieldTypes.Time: {
          momentObj = getDateTime(formInfo?.[name], 'hh:mm a');
          break;
        }
        case FormFieldTypes.Month: {
          momentObj = getDateTime(formInfo?.[name], 'YYYY-MM');
          break;
        }
        case FormFieldTypes.Year: {
          momentObj = checkForValidDateForIsoFormat(formInfo?.[name])
            ? getDate(formInfo?.[name], DateFormatters.YYYYMMDD)
            : checkForValidDate(formInfo?.[name])
            ? getDate(formInfo?.[name], DateFormatters.ddmmyyyy)
            : getDateTime(formInfo?.[name], 'YYYY');
          break;
        }
        case FormFieldTypes.DateRange: {
          const startOfQuater = checkForValidDateForIsoFormat(formInfo?.[name])
            ? getDate(formInfo?.[name], DateFormatters.YYYYMMDD)
            : getDate(formInfo?.[name], DateFormatters.ddmmyyyy);
          momentObj = startOfQuater.startOf('quarter');
          break;
        }
        default: {
          momentObj = checkForValidDateForIsoFormat(formInfo?.[name])
            ? getDate(formInfo?.[name], DateFormatters.YYYYMMDD)
            : getDate(formInfo?.[name], DateFormatters.ddmmyyyy);
          break;
        }
      }
    }
    if (operator && value && type) {
      switch (operator) {
        case '+': {
          momentObj = momentObj.add(value, type);
          break;
        }
        case '-': {
          momentObj = momentObj.subtract(value, type);
          break;
        }
      }
    }
    return momentObj;
  };

  const handleInputChange = (
    event,
    subtype,
    copyTo,
    isFormula = true,
    percisionNumber,
    isUpperCase
  ) => {
    const key = event.target.name;
    let val = event.target.value;

    if (subtype === FormFieldTypes.Number && val !== '' && percisionNumber) {
      const decimalRegex = new RegExp(`^\\d*\\.?\\d{0,${percisionNumber}}$`);
      if (!decimalRegex.test(val)) {
        val = val.slice(0, val.indexOf('.') + percisionNumber + 1);
      }
    }

    if (isUpperCase && typeof val === 'string') {
      val = val.toUpperCase();
    }

    if (val && subtype === FormFieldTypes.Email) {
      if (!validateEmail(val)) {
        setDisabledSave(true);
        setErrors((prev) => ({ ...prev, [key]: 'Invalid email format' }));
      } else {
        setDisabledSave(false);
        setErrors((prev) => ({ ...prev, [key]: '' }));
      }
    } else {
      setErrors((prev) => ({ ...prev, [key]: '' }));
    }
    setFormData((prev) => ({
      ...prev,
      [key]:
        subtype === FormFieldTypes.Number
          ? val !== ''
            ? Number(val)
            : null
          : val,
    }));
    if (copyTo) {
      setErrors((prev) => ({ ...prev, [copyTo]: '' }));
      setFormData((prev) => ({
        ...prev,
        [copyTo]: val,
      }));
    }

    if (
      key === FormFieldNames.Function &&
      formInfo?.[FormFieldNames.DateOfJoining]
    ) {
      const newDate = getFormattedValues(
        DateFormatters.ddmmyyyy,
        moment(formInfo?.[FormFieldNames.DateOfJoining], 'DD/MM/YYYY').add(
          val?.probationPeriodMonths || 3,
          'months'
        )
      );
      setFormData((prev) => ({
        ...prev,
        [copyTo]: newDate,
      }));
    }
    {
      isFormula && handleChangeFormulaFields(calculateSales?.percision);
    }
  };

  const handleCheckboxChange = (event) => {
    const key = event.target.name;
    const val = event.target.checked;
    setFormData((prev) => ({
      ...prev,
      [key]: val,
    }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
    handleChangeFormulaFields();
  };

  const handleFileChange = (event) => {
    const key = event.target.name;
    const val = event.target.files[0];
    setFileLoading(LoadingStatus.Loading);
    dispatch(fileUpload(val))
      .then((res) => {
        setFormData((prev) => ({
          ...prev,
          [key]: { id: res?.id, url: res?.url },
        }));
        setErrors((prev) => ({ ...prev, [key]: '' }));
        setFileLoading(LoadingStatus.Success);
        setFileRes({ ...fileRes, [key]: res });
        showToast(
          ToastMessages.success.replace(stringSubstitute, toastMessage.upload),
          ToastThemes.success
        );
      })
      .catch((err) => {
        showToast(err, ToastThemes.error);
        setFileLoading(LoadingStatus.Failure);
      });
    handleChangeFormulaFields();
  };
  const handleDateChange = (
    value,
    name,
    copyTo,
    minDate,
    maxDate,
    disableFuture,
    disablePast
  ) => {
    const formattedVal = getFormattedValues(DateFormatters.ddmmyyyy, value);
    if (name === FormFieldNames.DateOfJoining) {
      const newDate = getFormattedValues(
        DateFormatters.ddmmyyyy,
        moment(value).add(
          formInfo?.[FormFieldNames.Function]?.probationPeriodMonths || 3,
          'months'
        )
      );
      setFormData((prev) => ({
        ...prev,
        [copyTo]: newDate,
      }));
    }
    setFormData((prev) => ({
      ...prev,
      [name]: value ? formattedVal : '',
    }));
    const dateValidationErrors = DateRangeValidations(
      value,
      minDate,
      maxDate,
      disableFuture,
      disablePast,
      name,
      FormFieldTypes.Date
    );
    if (!isEmpty(dateValidationErrors)) {
      setErrors((prev) => ({ ...prev, ...dateValidationErrors }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
    handleChangeFormulaFields();
  };

  const handleTimeChange = (value, name) => {
    const formattedVal = formattedTimeValue(value, 'hh:mm a');
    setFormData((prev) => ({
      ...prev,
      [name]: formattedVal,
    }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
    handleChangeFormulaFields();
  };

  const handleMonthChange = (value, name) => {
    const formattedVal = formattedTimeValue(value, 'YYYY-MM');
    setFormData((prev) => ({
      ...prev,
      [name]: formattedVal,
    }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
    handleChangeFormulaFields(calculateSales?.percision);
  };

  const handleYearChange = (value, name) => {
    const formattedVal = formattedTimeValue(value, 'YYYY');
    setFormData((prev) => ({
      ...prev,
      [name]: formattedVal,
    }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
    handleChangeFormulaFields(calculateSales?.percision);
  };

  const handleDateTimeChange = (value, name) => {
    const formattedVal = formattedDateTimeValue(value);
    setFormData((prev) => ({
      ...prev,
      [name]: value ? formattedVal : '',
    }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
    handleChangeFormulaFields();
  };

  const handleChangeMultiSelect = (event, sysFieldId, fieldType) => {
    const key = event.target.name;
    const selected = event.target.value;
    const newVal = selected[selected.length - 1];
    const list = getDropdownOptions(key, sysFieldId);

    if (newVal?.id === 'all' && isAllSelected?.[key]) {
      setFormData((prev) => {
        if (fieldType === FormFieldTypes.TRF) {
          dispatch(getTRFClaimForm([])).catch((err) => {
            showToast(err, ToastThemes.error);
          });
        }
        return {
          ...prev,
          [key]: [],
        };
      });
      setIsAllSelected((prev) => ({
        ...prev,
        [key]: false,
      }));
    } else if (newVal?.id === 'all' && !isAllSelected?.[key]) {
      const newList = clone(list || []);
      const index = newList.findIndex((item) => item.id === newVal.id);
      if (index !== -1) {
        newList.splice(index, 1);
      }
      setFormData((prev) => {
        if (fieldType === FormFieldTypes.TRF) {
          const ids = newList.length && newList?.map((item) => Number(item.id));
          dispatch(getTRFClaimForm(ids || [])).catch((err) => {
            showToast(err, ToastThemes.error);
          });
        }
        return {
          ...prev,
          [key]: newList,
        };
      });
      setIsAllSelected((prev) => ({
        ...prev,
        [key]: newList.length > 0 && list?.length === newList.length,
      }));
    } else {
      const currentVal = clone(formInfo?.[key] || []);
      const index = currentVal.findIndex((item) => item.id === newVal.id);
      if (index !== -1) {
        currentVal.splice(index, 1);
      } else {
        currentVal.push(newVal);
      }
      setFormData((prev) => {
        if (fieldType === FormFieldTypes.TRF) {
          const ids = currentVal.length && currentVal?.map((item) => Number(item.id));
          dispatch(getTRFClaimForm(ids || [])).catch((err) => {
            showToast(err, ToastThemes.error);
          });
        }
        return {
          ...prev,
          [key]: currentVal,
        };
      });
      setIsAllSelected((prev) => ({
        ...prev,
        [key]: currentVal?.length > 0 && list?.length === currentVal?.length,
      }));
    }
    setErrors((prev) => ({ ...prev, [key]: '' }));
    handleChangeFormulaFields();
  };

  const handleChangeFormulaFields = (percisionNumber) => {
    const hourDiff = (startTime, endTime) => {
      const millisecondsDifference = getTimeDifferenceMilliseconds(
        startTime,
        endTime
      );
      return moment.duration(millisecondsDifference).asHours();
    };

    const minuteDiff = (startTime, endTime) => {
      const millisecondsDifference = getTimeDifferenceMilliseconds(
        startTime,
        endTime
      );
      return moment.duration(millisecondsDifference).asMinutes();
    };

    formItems.forEach((item) => {
      const { fieldRefName, formula, computeExpression } = item;
      if (formula || computeExpression) {
        setFormData((data) => {
          const evalExpr = getFormulaExpression(
            formula,
            data,
            computeExpression,
            publicHoliday
          );
          let val = eval(evalExpr);
          if (percisionNumber) {
            const decimalRegex = new RegExp(
              `^\\d*\\.?\\d{0,${percisionNumber}}$`
            );
            if (!decimalRegex.test(val)) {
              val = Number(
                String(val).slice(
                  0,
                  String(val).indexOf('.') + percisionNumber + 1
                )
              );
            }
          }
          return {
            ...data,
            [fieldRefName]: val < 0 ? 0 : val,
          };
        });
        setErrors((prev) => ({ ...prev, [fieldRefName]: '' }));
      }
    });
  };

  const handleInputBlur = (
    event,
    subtype,
    percisionNumber,
    isFormula = true
  ) => {
    const val = event.target.value;
    const name = event.target.name;
    if (subtype === FormFieldTypes.Number && percisionNumber && val !== '') {
      setFormData((prev) => ({
        ...prev,
        [name]: Number(parseFloat(val)?.toFixed(percisionNumber)),
      }));
    }
    {
      isFormula && handleChangeFormulaFields(calculateSales?.percision);
    }
  };

  const handleMultiFileChange = (event, fileUploadValidations) => {
    const key = event.target.name;
    const files = event.target.files;
    const { maxSize = 5, maxFiles = 3 } = fileUploadValidations || {};
    if (!fileRes) {
      setFileRes({ [key]: formInfo?.[key] });
    }

    for (const item of Object.values(files)) {
      if (item?.size > maxSize * 1024 * 1024) {
        showToast('File Size Exceeded', ToastThemes.error);
        return;
      }
    }

    if (formInfo?.[key]?.length + 1 > maxFiles) {
      showToast('Reached Maximum Upload Limit', ToastThemes.error);
      return;
    }

    Promise.all(
      Array.from(files).map((file) => {
        return new Promise((resolve, reject) => {
          setFileLoading(LoadingStatus.Loading);

          dispatch(fileUpload(file))
            .then((res) => {
              setFormData((prev) => ({
                ...prev,
                [key]: [...(prev?.[key] || []), { id: res?.id, url: res?.url }],
              }));

              setFileLoading(LoadingStatus.Success);
              setFileRes((prev) => ({
                ...prev,
                [key]: [...(prev?.[key] || []), res],
              }));
              setErrors((prev) => ({ ...prev, [key]: '' }));
              showToast(
                ToastMessages.success.replace(
                  stringSubstitute,
                  toastMessage.upload
                ),
                ToastThemes.success
              );
              resolve();
            })
            .catch((err) => {
              showToast(err, ToastThemes.error);
              setFileLoading(LoadingStatus.Failure);
              reject(err);
            });
        });
      })
    )
      .then(() => {
        handleChangeFormulaFields(calculateSales?.percision);
      })
      .catch((err) => {
        console.log('Error uploading files:', err);
      });
  };

  const handleDateRangeChange = (value, name) => {
    const formattedVal = [
      getFormattedValues(DateFormatters.YYYYMMDD, value[0]),
      getFormattedValues(DateFormatters.YYYYMMDD, value[1]),
    ];
    if (value[0].isBefore(value[1]) || value[0].isSame(value[1])) {
      setFormData((prev) => ({
        ...prev,
        [name]: value ? formattedVal : '',
      }));
    }
    setErrors((prev) => ({ ...prev, [name]: '' }));
    handleChangeFormulaFields(calculateSales?.percision);
  };

  const handleUserBasedSSL = async (event, sysField) => {
    const key = event.target.name;
    const val = event.target.value;

    if (val) {
      try {
        setFileLoading(LoadingStatus.Loading);
        const response = await new BuilderServices().userBasedValue({
          sysFieldId: sysField?.id,
          selectedValue: val?.id,
          claimTypeId,
        });
        setFileLoading(LoadingStatus.Success);
        setFormData((prev) => ({
          ...prev,
          [key]: val,
          ...response,
        }));
        setErrors((prev) => ({ ...prev, [key]: '' }));
      } catch (err) {
        setFileLoading(LoadingStatus.Failure);
        showToast(err?.response?.data?.message, ToastThemes.error);
        throw err?.response?.data?.message;
      }
    }
  };

  const handleCurrencyExchangeField = async (event, currenyExchangeConfig) => {
    const key = event.target.name;
    const val = event.target.value;
    if (val) {
      try {
        setFileLoading(LoadingStatus.Loading);
        const response = await new BuilderServices().currencyExchangeRate(
          val?.name,
          formInfo?.[currenyExchangeConfig?.filterDate?.id]
        );
        setFileLoading(LoadingStatus.Success);
        setFormData((prev) => ({
          ...prev,
          [key]: val,
          [currenyExchangeConfig?.target?.id]: response?.conversion_rate,
        }));
        setErrors((prev) => ({ ...prev, [key]: '' }));
      } catch (err) {
        setFileLoading(LoadingStatus.Failure);
        showToast(err?.response?.data?.message, ToastThemes.error);
        throw err?.response?.data?.message;
      }
    }
  };

  const renderDateField = (
    id,
    name,
    label,
    required,
    maxDate,
    minDate,
    defaultValue,
    subitem,
    isDisabled,
    copyTo,
    isReadOnly,
    infoIcon,
    dataInputFormat,
    disableFuture,
    disablePast,
    fieldType,
    shouldDisable
  ) => {
    return subitem ? (
      <Box display="flex" gap={5}>
        <Box width={300}>
          <DateField
            id={id}
            labelText={label}
            fullWidth
            required={required}
            onChange={handleDateChange}
            value={
              moment(formInfo?.[name], 'YYYY-MM-DD', true).isValid()
                ? moment(formInfo?.[name], 'YYYY-MM-DD').format(
                    DateFormatters.ddmmyyyy
                  )
                : formInfo?.[name]
            }
            name={name}
            errorMessage={errors?.[name]}
            maxDate={maxDate}
            minDate={minDate}
            defaultValue={defaultValue}
            disabled={isDisabled}
            copyTo={copyTo}
            isReadOnly={isReadOnly}
            infoIcon={infoIcon}
            disablePast={disablePast}
            disableFuture={disableFuture}
          />
        </Box>
        {
          <Box width={300}>
            {renderInputField(
              subitem.id,
              subitem.fieldRefName,
              subitem.fieldLabel,
              subitem.placeholderText,
              subitem.isRequired,
              subitem.dataType,
              subitem.isDisabled,
              subitem.textTransform,
              subitem.calc(formInfo[name])
            )}
          </Box>
        }
      </Box>
    ) : (
      <DateField
        id={id}
        labelText={label}
        fullWidth
        required={required}
        onChange={handleDateChange}
        value={
          moment(formInfo?.[name], 'YYYY-MM-DD', true).isValid()
            ? moment(formInfo?.[name], 'YYYY-MM-DD').format(
                DateFormatters.ddmmyyyy
              )
            : formInfo?.[name]
        }
        name={name}
        errorMessage={errors?.[name]}
        maxDate={maxDate}
        minDate={minDate}
        defaultValue={defaultValue}
        disabled={isDisabled}
        isReadOnly={isReadOnly}
        infoIcon={infoIcon}
        disablePast={disablePast}
        disableFuture={disableFuture}
        fieldType={fieldType}
        shouldDisable={shouldDisable}
      />
    );
  };

  const renderSelectField = (
    id,
    name,
    label,
    placeholder,
    required,
    subtype,
    isDisabled,
    copyTo,
    onChange,
    fieldType,
    hasCustomIcon,
    isReadOnly,
    infoIcon,
    sysField
  ) => (
    <ApiSelectTag
      id={id}
      labelText={label}
      defaultValue={placeholder}
      fullWidth
      required={required}
      size="small"
      onOpen={() => handleDropdownClick(name, sysField?.id)}
      loadingStatus={getListLoadingStatus(name)}
      dropDownList={getDropdownOptions(name, sysField?.id)}
      value={formInfo?.[name]}
      name={name}
      onchange={(event) =>
        onChange ? onChange(event) : handleInputChange(event, subtype, copyTo)
      }
      isMulti={fieldType === FormFieldTypes.MutliSelect || fieldType === FormFieldTypes.TRF}
      errorMessage={errors?.[name]}
      onChangeMultiSelect={(event) =>
        handleChangeMultiSelect(event, sysField?.id, fieldType)
      }
      disabled={isDisabled}
      hasCustomIcon={hasCustomIcon}
      isReadOnly={isReadOnly}
      infoIcon={infoIcon}
      isAllSelected={isAllSelected?.[name]}
    />
  );

  const renderInputField = (
    id,
    name,
    label,
    placeholder,
    required,
    subtype,
    isDisabled,
    textTransform,
    value,
    copyTo,
    multiline,
    infoIcon,
    onChange,
    minDate,
    maxDate,
    isReadOnly,
    percisionNumber,
    range,
    onKeyDown,
    removeDecimal,
    isUpperCase,
    maxLength
  ) => (
    <InputField
      id={id}
      labelText={label}
      placeholder={placeholder}
      fullWidth
      required={required}
      size="small"
      onChange={(event) =>
        onChange
          ? onChange(event)
          : handleInputChange(
              event,
              subtype,
              copyTo,
              true,
              percisionNumber,
              isUpperCase
            )
      }
      name={name}
      error={errors?.[name]}
      helperText={errors?.[name]}
      value={
        value ? value : formInfo?.[name] !== 0 ? formInfo?.[name] || '' : 0
      }
      disabled={isDisabled}
      textTransform={textTransform}
      type={getSubType(subtype)}
      multiline={
        multiline || subtype === FormFieldTypes.MultiLineText ? true : false
      }
      infoIcon={infoIcon}
      min={minDate}
      max={maxDate}
      isReadOnly={isReadOnly}
      onBlur={(event) => handleInputBlur(event, subtype, percisionNumber)}
      dataInputFormat={percisionNumber}
      onKeyDown={
        subtype === FormFieldTypes.Number
          ? removeDecimal
            ? preventKeyPressWithoutDecimal
            : onKeyDown || preventKeyPress
          : () => {}
      }
      range={range}
      maxLength={maxLength}
    />
  );

  const renderFileUpload = (
    id,
    name,
    label,
    placeholder,
    imageValidation,
    isDisabled,
    infoIcon,
    isReadOnly
  ) => (
    <FileUPload
      id={id}
      labelText={label}
      placeholder={placeholder}
      allowedImageType={imageValidation}
      onUpload={handleFileChange}
      name={name}
      disabled={isDisabled}
      fileRes={fileRes?.[name] || formInfo?.[name]}
      infoIcon={infoIcon}
      isReadOnly={isReadOnly}
      value={formInfo}
    />
  );

  const renderSearchMultiSelect = (
    id,
    name,
    label,
    placeholder,
    required,
    isDisabled
  ) => (
    <AutocompleteCheckbox
      id={id}
      fieldLabel={label}
      optionList={getDropdownOptions(name)}
      required={required}
      placeholder={placeholder}
    />
  );

  const renderTimeField = (
    id,
    name,
    label,
    required,
    placeholder,
    defaultValue,
    isDisabled,
    isReadOnly,
    dataInputFormat,
    infoIcon,
    maxDate,
    minDate,
    disableFuture,
    disablePast
  ) => (
    <TimeField
      id={id}
      labelText={label}
      fullWidth
      required={required}
      onChange={handleTimeChange}
      value={formInfo?.[name]}
      name={name}
      errorMessage={errors?.[name]}
      defaultValue={defaultValue}
      disabled={isDisabled}
      isReadOnly={isReadOnly}
      placeholder={placeholder}
      infoIcon={infoIcon}
      maxDate={maxDate}
      minDate={minDate}
      disableFuture={disableFuture}
      disablePast={disablePast}
    />
  );

  const renderDateTimeField = (
    id,
    name,
    label,
    required,
    placeholder,
    defaultValue,
    isDisabled,
    isReadOnly,
    dataInputFormat = 'DD/MM/YYYY hh:mm a',
    infoIcon,
    maxDate,
    minDate,
    disableFuture,
    disablePast
  ) => (
    <DateTimeField
      id={id}
      labelText={label}
      fullWidth
      required={required}
      onChange={handleDateTimeChange}
      value={
        formInfo?.[name]
          ? moment(formInfo?.[name], 'YYYY-MM-DD HH:mm')
              .utc(formInfo?.[name], 'YYYY-MM-DD HH:mm')
              .local()
              .format(dataInputFormat)
          : null
      }
      name={name}
      errorMessage={errors?.[name]}
      defaultValue={defaultValue}
      disabled={isDisabled}
      isReadOnly={isReadOnly}
      placeholder={placeholder}
      dataInputFormat={dataInputFormat}
      infoIcon={infoIcon}
      maxDate={maxDate}
      minDate={minDate}
      disableFuture={disableFuture}
      disablePast={disablePast}
    />
  );

  const renderRatingField = (
    id,
    name,
    label,
    required,
    isDisabled,
    infoIcon,
    isReadOnly
  ) => {
    return (
      <RatingInput
        id={id}
        name={name}
        value={formInfo?.[name]}
        required={required}
        disabled={isDisabled}
        label={label}
        onChange={handleInputChange}
        marginTop={4}
        infoIcon={infoIcon}
        isReadOnly={isReadOnly}
      />
    );
  };
  const renderCheckboxField = (
    id,
    name,
    label,
    required,
    isDisabled,
    infoIcon,
    isReadOnly,
    onChange
  ) => {
    return (
      <CheckBoxInput
        id={id}
        name={name}
        value={formInfo?.[name]}
        required={required}
        disabled={isDisabled}
        label={label}
        onChange={(event) =>
          onChange ? onChange(event) : handleCheckboxChange(event)
        }
        marginTop={4}
        infoIcon={infoIcon}
        isReadOnly={isReadOnly}
      />
    );
  };

  const renderButtonField = (
    id,
    name,
    label,
    required,
    isDisabled,
    infoIcon,
    isReadOnly,
    onClick
  ) => {
    return (
      <ButtonField
        id={id}
        name={name}
        value={formInfo?.[name]}
        required={required}
        disabled={isDisabled}
        label={label}
        marginTop={4}
        onClick={onClick}
        infoIcon={infoIcon}
        isReadOnly={isReadOnly}
      />
    );
  };

  const renderDateRangeField = (
    id,
    name,
    label,
    required,
    isDisabled,
    infoIcon,
    isReadOnly,
    maxDate,
    minDate,
    disableFuture,
    disablePast
  ) => {
    return (
      <DateRangeForm
        id={id}
        labelText={label}
        fullWidth
        required={required}
        onChange={handleDateRangeChange}
        value={formInfo?.[name]}
        name={name}
        errorMessage={errors?.[name]}
        disabled={isDisabled}
        infoIcon={infoIcon}
        isReadOnly={isReadOnly}
        maxDate={maxDate}
        minDate={minDate}
        disableFuture={disableFuture}
        disablePast={disablePast}
      />
    );
  };

  const renderMultiFileUpload = (
    id,
    name,
    label,
    placeholder,
    imageValidation,
    isDisabled,
    infoIcon,
    isReadOnly,
    isRequired,
    fileUploadValidations
  ) => {
    const allowedTypes = fileUploadValidations?.allowedFiles
      ?.map((item) => item?.id)
      .join(', ');
    return (
      <MutliFileUpload
        id={id}
        labelText={label}
        placeholder={placeholder}
        allowedImageType={imageValidation || allowedTypes}
        onUpload={(event) =>
          handleMultiFileChange(event, fileUploadValidations)
        }
        name={name}
        disabled={isDisabled}
        fileRes={fileRes?.[name] || formInfo?.[name]}
        infoIcon={infoIcon}
        isReadOnly={isReadOnly}
        value={formInfo}
        setFormData={setFormData}
        setFileRes={setFileRes}
        required={isRequired}
        errorMessage={errors?.[name]}
      />
    );
  };

  const renderMonthField = (
    id,
    name,
    label,
    required,
    maxDate,
    minDate,
    defaultValue,
    subitem,
    isDisabled,
    copyTo,
    isReadOnly,
    infoIcon,
    dataInputFormat,
    disableFuture,
    disablePast,
    monthDataArray
  ) => {
    return (
      <MonthField
        id={id}
        labelText={label}
        fullWidth
        required={required}
        onChange={handleMonthChange}
        value={formInfo?.[name]}
        name={name}
        errorMessage={errors?.[name]}
        maxDate={maxDate}
        minDate={minDate}
        defaultValue={defaultValue}
        disabled={isDisabled}
        isReadOnly={isReadOnly}
        infoIcon={infoIcon}
        disablePast={disablePast}
        disableFuture={disableFuture}
        monthDataArray={monthDataArray}
      />
    );
  };

  const renderYearField = (
    id,
    name,
    label,
    required,
    maxDate,
    minDate,
    defaultValue,
    subitem,
    isDisabled,
    copyTo,
    isReadOnly,
    infoIcon,
    dataInputFormat,
    disableFuture,
    disablePast
  ) => (
    <YearField
      id={id}
      labelText={label}
      fullWidth
      required={required}
      onChange={handleYearChange}
      value={formInfo?.[name]}
      name={name}
      errorMessage={errors?.[name]}
      maxDate={maxDate}
      minDate={minDate}
      defaultValue={defaultValue}
      disabled={isDisabled}
      isReadOnly={isReadOnly}
      infoIcon={infoIcon}
      disablePast={disablePast}
      disableFuture={disableFuture}
    />
  );

  const renderFormulaField = (
    id,
    name,
    label,
    placeholder,
    required,
    subtype,
    isDisabled,
    textTransform,
    value,
    copyTo,
    multiline,
    infoIcon,
    onChange,
    minDate,
    maxDate,
    isReadOnly,
    percisionNumber,
    onKeyDown,
    removeDecimal,
    isUpperCase
  ) => (
    <InputField
      id={id}
      labelText={label}
      placeholder={placeholder}
      fullWidth
      required={required}
      size="small"
      onChange={(event) =>
        onChange
          ? onChange(event)
          : handleInputChange(
              event,
              FormFieldTypes.Number,
              copyTo,
              isReadOnly ?? true,
              percisionNumber,
              isUpperCase
            )
      }
      name={name}
      error={errors?.[name]}
      helperText={errors?.[name]}
      value={formInfo?.[name] || 0}
      disabled={isDisabled}
      textTransform={textTransform}
      type="number"
      multiline={
        multiline || subtype === FormFieldTypes.MultiLineText ? true : false
      }
      infoIcon={infoIcon}
      min={minDate}
      max={maxDate}
      isReadOnly={isReadOnly ?? true}
      onBlur={(event) =>
        handleInputBlur(
          event,
          FormFieldTypes.Number,
          percisionNumber,
          isReadOnly ?? true
        )
      }
      dataInputFormat={percisionNumber ?? 2}
      onKeyDown={
        removeDecimal
          ? preventKeyPressWithoutDecimal
          : onKeyDown || preventKeyPress
      }
    />
  );

  const renderUserBasedSelectField = (
    id,
    name,
    label,
    placeholder,
    required,
    subtype,
    isDisabled,
    copyTo,
    onChange,
    fieldType,
    hasCustomIcon,
    isReadOnly,
    infoIcon,
    sysField
  ) => (
    <ApiSelectTag
      id={id}
      labelText={label}
      defaultValue={placeholder}
      fullWidth
      required={required}
      size="small"
      onOpen={() => handleDropdownClick(name, sysField?.id)}
      loadingStatus={getListLoadingStatus(name)}
      dropDownList={getDropdownOptions(name, sysField?.id)}
      value={formInfo?.[name]}
      name={name}
      onchange={(event) =>
        onChange ? onChange(event) : handleUserBasedSSL(event, sysField)
      }
      isMulti={fieldType === FormFieldTypes.MutliSelect}
      errorMessage={errors?.[name]}
      onChangeMultiSelect={handleChangeMultiSelect}
      disabled={isDisabled}
      hasCustomIcon={hasCustomIcon}
      isReadOnly={isReadOnly}
      infoIcon={infoIcon}
    />
  );

  const renderCurrencyExchangeField = (
    id,
    name,
    label,
    placeholder,
    required,
    subtype,
    isDisabled,
    copyTo,
    onChange,
    fieldType,
    hasCustomIcon,
    isReadOnly,
    infoIcon,
    sysField,
    currenyExchangeConfig
  ) => (
    <ApiSelectTag
      id={id}
      labelText={label}
      defaultValue={placeholder}
      fullWidth
      required={required}
      size="small"
      onOpen={() => handleDropdownClick(name, sysField?.id)}
      loadingStatus={getListLoadingStatus(name)}
      dropDownList={getDropdownOptions(name, sysField?.id)}
      value={formInfo?.[name]}
      name={name}
      onchange={(event) =>
        onChange
          ? onChange(event)
          : handleCurrencyExchangeField(event, currenyExchangeConfig)
      }
      isMulti={fieldType === FormFieldTypes.MutliSelect}
      errorMessage={errors?.[name]}
      onChangeMultiSelect={handleChangeMultiSelect}
      disabled={isDisabled}
      hasCustomIcon={hasCustomIcon}
      isReadOnly={isReadOnly}
      infoIcon={infoIcon}
    />
  );

  const getFields = (
    fieldType,
    id,
    name,
    label,
    placeholder,
    required,
    subtype,
    isDisabled,
    imageValidation,
    maxDate,
    minDate,
    defaultValue,
    subitem,
    textTransform,
    copyTo,
    onChange,
    multiline,
    infoIcon,
    isReadOnly,
    hasCustomIcon,
    dataInputFormat,
    onClick,
    sysField,
    disableFuture,
    disablePast,
    percisionNumber,
    isCurrencyFormater,
    fileUploadValidations,
    monthDataArray,
    range,
    onKeyDown,
    shouldDisable,
    removeDecimal,
    isUpperCase,
    maxLength,
    currenyExchangeConfig
  ) => {
    switch (fieldType) {
      case FormFieldTypes.Date:
        return renderDateField(
          id,
          name,
          label,
          required,
          maxDate,
          minDate,
          defaultValue,
          subitem,
          isDisabled,
          copyTo,
          isReadOnly,
          infoIcon,
          dataInputFormat,
          disableFuture,
          disablePast,
          fieldType,
          shouldDisable
        );
      case FormFieldTypes.SingleSelect:
      case FormFieldTypes.MutliSelect:
      case FormFieldTypes.TRF: 
        return renderSelectField(
          id,
          name,
          label,
          placeholder,
          required,
          subtype,
          isDisabled,
          copyTo,
          onChange,
          fieldType,
          hasCustomIcon,
          isReadOnly,
          infoIcon,
          sysField
        );
      case FormFieldTypes.File:
        return renderFileUpload(
          id,
          name,
          label,
          placeholder,
          imageValidation,
          isDisabled,
          infoIcon,
          isReadOnly
        );
      case FormFieldTypes.SearchMultiSelect:
        return renderSearchMultiSelect(
          id,
          name,
          label,
          placeholder,
          required,
          isDisabled
        );
      case FormFieldTypes.Time:
        return renderTimeField(
          id,
          name,
          label,
          required,
          placeholder,
          defaultValue,
          isDisabled,
          isReadOnly,
          dataInputFormat,
          infoIcon,
          maxDate,
          minDate,
          disableFuture,
          disablePast
        );
      case FormFieldTypes.Rating:
        return renderRatingField(
          id,
          name,
          label,
          required,
          isDisabled,
          infoIcon,
          isReadOnly
        );
      case FormFieldTypes.DateTime:
        return renderDateTimeField(
          id,
          name,
          label,
          required,
          placeholder,
          defaultValue,
          isDisabled,
          isReadOnly,
          dataInputFormat,
          infoIcon,
          maxDate,
          minDate,
          disableFuture,
          disablePast
        );
      case FormFieldTypes.CheckBox:
        return renderCheckboxField(
          id,
          name,
          label,
          required,
          isDisabled,
          infoIcon,
          isReadOnly,
          onChange
        );
      case FormFieldTypes.Button:
        return renderButtonField(
          id,
          name,
          label,
          required,
          isDisabled,
          infoIcon,
          isReadOnly,
          onClick
        );
      case FormFieldTypes.Formula:
        return renderFormulaField(
          id,
          name,
          label,
          placeholder,
          required,
          subtype,
          isDisabled,
          textTransform,
          undefined,
          copyTo,
          multiline,
          infoIcon,
          onChange,
          minDate,
          maxDate,
          isReadOnly,
          percisionNumber,
          range,
          onKeyDown,
          removeDecimal,
          isUpperCase
        );
      case FormFieldTypes.DateRange:
        return renderDateRangeField(
          id,
          name,
          label,
          required,
          isDisabled,
          infoIcon,
          isReadOnly,
          maxDate,
          minDate,
          disableFuture,
          disablePast
        );
      case FormFieldTypes.UserBasedField:
      case FormFieldTypes.AutoPopulate:
      case FormFieldTypes.EntitlementAutoPopulate:
      case FormFieldTypes.ProRateField:
        return (
          <InputField
            id={id}
            labelText={label}
            placeholder={placeholder}
            fullWidth
            required={required}
            size="small"
            onChange={(event) => (onChange ? onChange(event) : () => {})}
            name={name}
            error={errors?.[name]}
            helperText={errors?.[name]}
            value={formInfo?.[name]}
            disabled={isDisabled}
            textTransform={textTransform}
            type="text"
            multiline={
              multiline || subtype === FormFieldTypes.MultiLineText
                ? true
                : false
            }
            infoIcon={infoIcon}
            min={minDate}
            max={maxDate}
            isReadOnly={true}
          />
        );
      case FormFieldTypes.MultiFileUpload:
        return renderMultiFileUpload(
          id,
          name,
          label,
          placeholder,
          imageValidation,
          isDisabled,
          infoIcon,
          isReadOnly,
          required,
          fileUploadValidations
        );

      case FormFieldTypes.Month:
        return renderMonthField(
          id,
          name,
          label,
          required,
          maxDate,
          minDate,
          defaultValue,
          subitem,
          isDisabled,
          copyTo,
          isReadOnly,
          infoIcon,
          dataInputFormat,
          disableFuture,
          disablePast,
          monthDataArray
        );
      case FormFieldTypes.Year:
        return renderYearField(
          id,
          name,
          label,
          required,
          maxDate,
          minDate,
          defaultValue,
          subitem,
          isDisabled,
          copyTo,
          isReadOnly,
          infoIcon,
          dataInputFormat,
          disableFuture,
          disablePast
        );
      case FormFieldTypes.UserBasedSSL:
        return renderUserBasedSelectField(
          id,
          name,
          label,
          placeholder,
          required,
          subtype,
          isDisabled,
          copyTo,
          onChange,
          fieldType,
          hasCustomIcon,
          isReadOnly,
          infoIcon,
          sysField
        );
      case FormFieldTypes.CurrencyExchangeField:
        return renderCurrencyExchangeField(
          id,
          name,
          label,
          placeholder,
          required,
          subtype,
          isDisabled,
          copyTo,
          onChange,
          fieldType,
          hasCustomIcon,
          isReadOnly,
          infoIcon,
          sysField,
          currenyExchangeConfig
        );
      default:
        return renderInputField(
          id,
          name,
          label,
          placeholder,
          required,
          subtype,
          isDisabled,
          textTransform,
          undefined,
          copyTo,
          multiline,
          infoIcon,
          onChange,
          minDate,
          maxDate,
          isReadOnly,
          percisionNumber,
          range,
          onKeyDown,
          removeDecimal,
          isUpperCase,
          maxLength
        );
    }
  };
  const renderList = () => {
    const formList = hideFields
      ? formItems.filter((item) => !item.isHidden)
      : formItems;
    return formList.map((item, index) => {
      const {
        fieldType,
        id,
        fieldRefName,
        fieldLabel,
        placeholderText,
        isRequired,
        dataType,
        isDisabled,
        imageValidation,
        maxDate,
        minDate,
        defaultValue,
        subitem,
        textTransform,
        copyTo,
        onChange,
        multiline,
        tooltipText,
        fieldSize = 6,
        isReadOnly,
        hasCustomIcon,
        dataInputFormat,
        onClick,
        sysField,
        disableFuture,
        disablePast,
        percisionNumber,
        isCurrencyFormater,
        fileUploadValidations,
        monthDataArray,
        range,
        onKeyDown,
        shouldDisable,
        removeDecimal,
        toUpperCase,
        maxLength,
        currenyExchangeConfig,
      } = item;

      return (
        <Grid
          item
          xs={12}
          md={fieldSize}
          key={id}
          pr={
            fieldSize === 12 && dataType !== FormFieldTypes.MultiLineText
              ? '63.5%'
              : padding
          }
        >
          <Box
            maxWidth="auto"
            width={modal ? (fieldSize < 6 ? 250 : 'auto') : 'auto'}
          >
            {getFields(
              fieldType,
              id,
              fieldRefName,
              fieldLabel,
              placeholderText,
              isRequired,
              dataType,
              isDisabled,
              imageValidation,
              maxDate,
              minDate,
              defaultValue,
              subitem,
              textTransform,
              copyTo,
              onChange,
              multiline,
              tooltipText,
              isReadOnly,
              hasCustomIcon,
              dataInputFormat,
              onClick,
              sysField,
              disableFuture,
              disablePast,
              percisionNumber,
              isCurrencyFormater,
              fileUploadValidations,
              monthDataArray,
              range,
              onKeyDown,
              shouldDisable,
              removeDecimal,
              toUpperCase,
              maxLength,
              currenyExchangeConfig
            )}
          </Box>
          {button && (
            <>
              <Button onClick={() => editClick(item, index)}>Edit</Button>
              <Button onClick={() => deleteClick(index)} color="error">
                Delete
              </Button>
            </>
          )}
        </Grid>
      );
    });
  };

  const getMinimumMaximumValue = (value) => {
    return isNaN(value) ? Number(formInfo[value]) : Number(value);
  };

  const validateRange = (fieldRefName, fieldType, dataType, range) => {
    if (
      fieldType === FormFieldTypes.Text &&
      dataType === FormFieldTypes.Number
    ) {
      let errors = {};
      const { min, max, minType, maxType } = range || {};
      if (min !== undefined || min !== null) {
        const minValue = getMinimumMaximumValue(min);
        const minError = NumberValidation(
          minType,
          minValue,
          formInfo[fieldRefName],
          fieldRefName
        );
        errors = { ...errors, ...minError };
      }
      if (max !== undefined || max !== null) {
        const maxValue = getMinimumMaximumValue(max);
        const maxerror = NumberValidation(
          maxType,
          maxValue,
          formInfo[fieldRefName],
          fieldRefName
        );
        errors = { ...errors, ...maxerror };
      }
      return errors;
    }
  };

  const requiredFields = useMemo(() => {
    return getHiddenMandatoryFields(formItems);
  }, [formItems]);

  const handleValidationOnSave = (saveAction) => {
    let rangeErrors = {};
    let zeroError = '';
    formItems.forEach((item) => {
      const {
        fieldRefName,
        fieldType,
        dataType,
        range,
        isRequired,
        isHidden,
        minDate,
        maxDate,
        disableFuture,
        disablePast,
      } = item;
      if (
        (isRequired && !isHidden) ||
        (!isRequired && !isHidden && formInfo[fieldRefName])
      ) {
        const rangeValidationError = validateRange(
          fieldRefName,
          fieldType,
          dataType,
          range
        );
        const dateValidationErrors = DateRangeValidations(
          formInfo?.[fieldRefName],
          minDate,
          maxDate,
          disableFuture,
          disablePast,
          fieldRefName,
          fieldType
        );
        rangeErrors = {
          ...rangeErrors,
          ...rangeValidationError,
          ...dateValidationErrors,
        };
      }
      const zeroCheck = checkForZero(fieldRefName, isHidden, formInfo);

      if (zeroCheck) {
        zeroError = zeroCheck;
      }
    });
    const validationErrors = getValidationErrors(
      formInfo || {},
      requiredFields
    );
    if (isEmpty(validationErrors) && isEmpty(rangeErrors) && zeroError === '') {
      saveAction();
    } else {
      setErrors((prev) => ({ ...prev, ...rangeErrors, ...validationErrors }));
      zeroError !== '' && showToast(zeroError, ToastThemes.error);
    }
  };

  const handleSave = () => {
    handleValidationOnSave(onPublishClick);
  };

  const handleSaveandCreateNew = () => {
    handleValidationOnSave(handleSaveAndAddNewClick);
    setFileRes();
  };

  const handleSaveandCopy = () => {
    handleValidationOnSave(handleSaveAndAddCopyClick);
  };

  const handleSalesCalculateClick = () => {
    new BuilderServices()
      .postProRataFactor({
        factorType: formInfo?.typeOfCommision?.name,
        quarterDates: formInfo?.dateRange,
        year: formInfo?.year,
      })
      .then((res) => {
        setFormData((prev) => ({
          ...prev,
          ...res,
        }));
        const percision = formItems
          .map((item) => {
            const { fieldType, fieldRefName, isReadOnly, percisionNumber } =
              item;
            if (
              fieldType === FormFieldTypes.Formula &&
              fieldRefName === 'amount' &&
              !isReadOnly
            ) {
              return percisionNumber;
            }
            return null;
          })
          .filter((item) => item !== null);
        handleChangeFormulaFields(percision[0]);
        setCalculateSales({
          calculate: !calculateSales?.calculate,
          percision: percision[0],
        });
      })
      .catch((err) => {
        showToast(err?.response?.data?.message, ToastThemes.error);
        throw err?.response?.data?.message;
      });
  };

  return (
    <Box>
      <CircularLoader show={fileLoading === LoadingStatus.Loading} />
      {isActionHeader && (
        <Box mb={3} mx={-3} mt={-3}>
          <ActionHeader
            onPublishClick={handleSave}
            {...actionHeaderProps}
            handleFirstButtonClick={handleSaveandCopy}
            handleSecondButtonClick={handleSaveandCreateNew}
          />
        </Box>
      )}
      <Box sx={{ flexGrow: 1 }}>
        <Grid container rowSpacing={3} columnSpacing={8} pr={[0, 0, 0]}>
          {renderList()}
        </Grid>
        {calculate(handleSalesCalculateClick)}
      </Box>
    </Box>
  );
};

export default FormEdit;
