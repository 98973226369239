import { Box, Grid, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import ActionHeader from '../../../../OnBoarding/Organization/ActionHeader/ActionHeader';
import FormView from '../../../../OnBoarding/common/Form/FormView';
import ApprovalWorkflowFooterCommon from '../../../../OnBoarding/Organization/ApprovalFlow/Footer/ApprovalWorkflowFooterCommon';
import ApprovalWorkflowAuditLog from '../../../../OnBoarding/Organization/ApprovalFlow/ApprovalWorkflowUtilsCommon/ApprovalWorkflowAuditTrail';
import { approvalWorkflowServices } from '../../../../../services/approvalWokflowService';
import ReasonIssueList from '../../../../../constants/Reasons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { BuilderServices } from '../../../../../services/builder';
import { PayrollStatus } from '../../../../Payroll/Constant/Constant';
import ModalDialog from '../../../../../atoms/Modal/ModalDialog';
import { showToast } from '../../../../../utils/common.util';
import {
  ToastMessages,
  stringSubstitute,
} from '../../../../OnBoarding/Organization/constants/onboarding.constants';
import {
  FormFieldTypes,
  ToastThemes,
  toastMessage,
} from '../../../../../constants/common';
import { EntitlementsService } from '../../../../../services/entitlementsService';
import {
  getFormBody,
  getHiddenMandatoryFields,
  getValidationErrors,
} from '../../../../OnBoarding/Organization/utils/userOnboarding/userOnboarding.util';
import { isEmpty } from 'lodash';
import { readConditions } from '../../../../OnBoarding/common/Form/util/form.util';
import {
  getFormattedValues,
  getPaymentDate,
  getSGTTimeZone,
} from '../../../../../utils/formatter';
import { PaymentMode } from '../../../../form-builder/utils/builder-utils';

const ClaimViewForm = (props) => {
  const {
    setViewMode,
    claimFormData,
    formConfig,
    claimName,
    moduleId,
    auditTrail,
    claimId,
    claimTypeId,
    allowEdit,
    permissions,
    isEditable,
    setIsEditable,
    setValidationErrors,
    isMultiDepartment,
    benefitId,
    paymentModeId,
    approvalRemarksPermission,
  } = props;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { logoImage } = useSelector((state) => state && state.onBoarding);
  const defaultTimezone = logoImage?.defaultTimezone;
  const defaultAduitLogTimezone = logoImage?.defaultAduitLogTimezone;
  const claimCycleDates = logoImage?.claimCycleDates;
  const { publicHoliday } = useSelector((state) => state && state.onBoarding);
  const [isAuthorized, setisAuthorized] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  const paymentDate = getSGTTimeZone(
    claimFormData?.paymentDate,
    'DD/MM/YYYY',
    defaultTimezone
  );
  const paymentMonth = getFormattedValues(
    'MMM YYYY',
    claimFormData?.paymentDate
  );
  const handleDeleteDraftclaim = async () => {
    setDeleteModalOpen(false);
    const approvalService = new approvalWorkflowServices();
    await approvalService
      .deleteDraftClaims(claimFormData?.id, claimTypeId, 'clm_claim_register')
      .then(async () => {
        showToast(
          ToastMessages.success.replace(stringSubstitute, toastMessage.delete),
          ToastThemes.success
        );
        navigate(-1);
      })
      .catch((err) => {
        showToast(
          ToastMessages.failed.replace(stringSubstitute, toastMessage.delete),
          ToastThemes.error
        );
      });
  };

  const requiredFields = useMemo(() => {
    return formConfig.filter((item) => {
      let temp = item;
      const { conditional } = temp;
      if (conditional) {
        const { rules, action } = conditional;
        const isValid = rules.some((rule) => {
          const { conjunction, conditions } = rule;
          if (
            readConditions(
              conditions,
              conjunction,
              claimFormData,
              publicHoliday
            )
          ) {
            return true;
          }
        });
        if (action)
          temp = {
            ...temp,
            [action]: !isValid,
          };
      }
      return temp.isRequired && !temp.isHidden;
    });
  }, [formConfig, claimFormData]);

  const beforeApprovalValidations = () => {
    if (!allowEdit) return true;
    const errors = getValidationErrors(claimFormData || {}, requiredFields);
    if (isEmpty(errors)) {
      return true;
    }
    setViewMode(false);
    setValidationErrors(errors);
    return false;
  };

  const revertEnitilements = async (status) => {
    switch (status) {
      case PayrollStatus.Rejected: {
        const entitlementsService = new EntitlementsService();
        await entitlementsService.revertEntitlements(
          'REJECTED',
          Number(claimFormData?.id),
          Number(moduleId)
        );
        break;
      }
      case PayrollStatus.SendBack: {
        if (benefitId || isMultiDepartment) {
          const entitlementsService = new EntitlementsService();
          await entitlementsService.revertEntitlements(
            'SENT BACK',
            Number(claimFormData?.id),
            Number(moduleId)
          );
        }
        break;
      }
    }
  };

  const saveDetails = useCallback(async (action, data) => {
    switch (action) {
      case PayrollStatus.Approved: {
        const paymentDate = getFormBody(data);
        new BuilderServices().statusUpdate({
          action: action === PayrollStatus.Approved ? 'APPROVED' : 'REJECTED',
          claimId,
          claimTypeId,
          ...paymentDate,
        });
        break;
      }
      case PayrollStatus.Rejected: {
        new BuilderServices().statusUpdate({
          action: action === PayrollStatus.Approved ? 'APPROVED' : 'REJECTED',
          claimId,
          claimTypeId,
        });
        break;
      }
      case PayrollStatus.SendBack: {
        new BuilderServices().statusUpdate({
          action: 'SENT BACK',
          claimId,
          claimTypeId,
        });
        break;
      }
      case PayrollStatus.Pending: {
        new BuilderServices().statusUpdate({
          action: 'PENDING',
          claimId,
          claimTypeId,
        });
        break;
      }
    }
  }, []);

  const isLastApproval = (data) => {
    if (
      data &&
      data.length > 2 &&
      data[data.length - 2].status === 'APPROVED'
    ) {
      return true;
    } else if (
      data &&
      data.length === 2 &&
      data[data.length - 2].status === 'CREATED'
    ) {
      return true;
    }
    return false;
  };

  return (
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <Box mb={3} mx={-3} mt={-3}>
          <ActionHeader
            labelText={claimName || 'New Claim'}
            editButtonText="Edit"
            onEditClick={() => setViewMode(false)}
            showSave={
              isEditable ||
              (allowEdit &&
                (claimFormData?.status === 'DRAFT' ||
                  claimFormData?.status === 'PENDING' ||
                  claimFormData?.status === 'SENT BACK')) ||
              (permissions?.allowEdit && claimFormData?.status === 'DRAFT')
            }
            showBackButton
            showDiscard={claimFormData?.status === 'DRAFT'}
            onClickDiscard={() => setDeleteModalOpen(true)}
            discardButtonText={'Delete'}
            onBackClick={() => navigate(`/new-claims/${claimTypeId}`)}
            labelCode={claimFormData.referenceNumber || ''}
            empId={
              claimFormData?.paymentDate
                ? paymentModeId === PaymentMode.PaymentDateThroughApprove ||
                  paymentModeId === PaymentMode.PaymentDateThroughPayroll
                  ? `Payment Date: ${paymentDate}`
                  : `Payment Month: ${paymentMonth}`
                : '' || ''
            }
          />
        </Box>
        <Grid container rowSpacing={3} columnSpacing={3}>
          <Grid item md={8}>
            <FormView
              list={formConfig}
              info={claimFormData}
              publicHoliday={publicHoliday}
            />
          </Grid>
          <Grid item md={4}>
            <Box
              ml={6}
              pl={6}
              sx={{ borderLeft: 'solid 1px', borderColor: 'neutral.dark40' }}
              mb={3}
            >
              <Typography variant="subtitle2" color={'neutral.dark80'} mb={3}>
                Audit Trail
              </Typography>
              <ApprovalWorkflowAuditLog
                auditTrail={auditTrail}
                defaultTimezone={defaultAduitLogTimezone}
              />
            </Box>
          </Grid>
        </Grid>
        <ApprovalWorkflowFooterCommon
          recordId={claimFormData?.id}
          moduleId={moduleId}
          auditLogs={auditTrail}
          reasons={ReasonIssueList}
          handleStatusUpdate={revertEnitilements}
          refId={claimFormData?.referenceNumber}
          setIsEditable={setIsEditable}
          list={[
            {
              fieldLabel: 'Payment Date',
              placeholderText: 'dd/mm/yyyy',
              fieldType: FormFieldTypes.Date,
              isRequired: false,
              fieldRefName: 'paymentDateReceived',
              fieldSize: 13,
              defaultValue: getPaymentDate(defaultTimezone, claimCycleDates),
            },
          ]}
          saveDetails={saveDetails}
          isAdditionalInfo={
            (paymentModeId === PaymentMode.PaymentDateThroughApprove &&
              isLastApproval(auditTrail)) ||
            false
          }
          handleApprove={() => navigate(-1)}
          beforeApprovalValidations={beforeApprovalValidations}
          claimRaisingFor={claimFormData?.userBasedDropdown?.id}
          dataForApproval={{
            amount: claimFormData?.amount,
            claimantName: `${claimFormData?.createdBy?.firstName} ${claimFormData?.createdBy?.lastName}`,
            distance: claimFormData?.distance,
            computedAmount: claimFormData?.computedAmount,
          }}
          isAuthorized={isAuthorized}
          setisAuthorized={setisAuthorized}
          approvalRemarksPermission={approvalRemarksPermission}
        />
        <ModalDialog
          showModal={deleteModalOpen}
          onClickConfirm={handleDeleteDraftclaim}
          onClickClose={handleCloseDeleteModal}
          description={'Are you sure want to delete the claim?'}
          title={'Delete Claim'}
          primaryAction={'Delete'}
          secondaryAction={'Cancel'}
        />
      </Box>
    </Box>
  );
};

export default ClaimViewForm;
